<template>
  <v-container>
    <row-header action="back" :msg="msgRowHeader"></row-header>

    <v-row dense>
      <v-col>
        <v-form
            ref="form"
            v-model="valid"
        >
          <v-card>
            <deposit-form-component
                :deposit_data="deposit_data"
                :status_list="status_list"
                update="1"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!valid || ($route.params.status.toString() === '0' || $route.params.status.toUpperCase() === 'SUCCESS')"
                color="success"
                @click="saveData"
                v-if="isAdmin"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-overlay
        :absolute="absolute"
        :value="overlay"
    >
      <v-card color="white">
        <v-card-text>
          <overlay-img></overlay-img>
        </v-card-text>
      </v-card>
    </v-overlay>

  </v-container>
</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import OverlayImg from "../../../components/content/OverlayImg";
import DepositFormComponent from '../../../components/page/trx/DepositFormComponent';
import { DepositData, StatusList } from '../../../store/services/TrxModel';
import { PutRequest, GetRequest } from "../../../store/services/users";

export default {
  components: {
    RowHeader,
    OverlayImg,
    DepositFormComponent,
  },
  data: () => ({
    msgRowHeader: {newUser: 'Back', newMsg: 'Update Merchant'},
    valid: true,
    absolute: false,
    overlay: false,
    deposit_data: new DepositData(),
    status_list: new StatusList(),
    isAdmin: false,
  }),
  async mounted() {
    console.log('category update view');
    if (this.$store.getters.isLoggedIn) {
      if (this.$store.getters.currentUser.role.level === 1) {
        this.isAdmin = true;
      }
      await this.getDepositData();
    }
  },
  methods: {
    async getDepositData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const response = await GetRequest('transactions/' + this.$route.params.tx_id);
        delete response.data.findOne._id;
        delete response.data.findOne.created_at;
        delete response.data.findOne.created_by;
        delete response.data.findOne.updated_at;
        delete response.data.findOne.updated_by;
        this.deposit_data = response.data.findOne;
        this.deposit_data.img = response.data.findOneDeposit.img;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Get user data failed ' + e.message);
      }
    },
    async saveData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      this.deposit_data.amount = Number(this.deposit_data.amount);
      try {
        console.log(this.category_model);
        const response = await PutRequest('transactions/deposit/' + this.$route.params.tx_id, this.deposit_data);
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        if (response.data && response.message === 'updated deposit') {
          alert('success to updated data');
          await this.$router.push({name: 'DepositList'});
        } else {
          alert('failed to updated data');
        }
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Get data failed ' + e.message);
      }
    },
  }
}
</script>

<style scoped>

</style>
